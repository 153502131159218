<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card header="Bevestigen" class="mt-4">
          <page-snippet name="release-create-outtro" class="" />

          <field
            name="DeliveryStatus"
            :defs="fieldDefs"
            :model="release"
             />

          <template v-if="$root.$data.me.AllowManageReleaseConcepts">
            <!--
            <strong>Kies type</strong>
            <b-select
              :options="testOptions"
              v-model="testOption"
            /> -->

            <div class="mt-5" v-if="createdRelease">
              <p>{{ createdRelease.Status }}</p>
              <p>{{ createdRelease.ApiError }}</p>
            </div>
          </template>

          <b-button class="mt-4" @click="$emit('create', testOption)">Playlist aanmaken</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

  export default {
    name: 'Confirm',
    components: {
      'page-snippet': () => import('@/components/PageSnippet'),
      'field': () => import('@/components/ReleaseConceptField'),
    },
    data () {
      return {
        fieldDefs: this.$root.$data.fieldDefs.Album,
        testOption: 'commit',
        testOptions: [
          { text: 'Test API call, niet echt aanmaken.', value: 'nocommit' },
          { text: 'Echte API call, release aanmaken.', value: 'commit' },
        ]
      }
    },
    props: {
      release: Object,
      createdRelease: Object,
    },
    watch: {
    },
    methods: {

    },
    computed: {
      readyToProceed () {
        return false
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>

</style>