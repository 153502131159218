var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                { staticClass: "mt-4", attrs: { header: "Bevestigen" } },
                [
                  _c("page-snippet", {
                    attrs: { name: "release-create-outtro" }
                  }),
                  _c("field", {
                    attrs: {
                      name: "DeliveryStatus",
                      defs: _vm.fieldDefs,
                      model: _vm.release
                    }
                  }),
                  _vm.$root.$data.me.AllowManageReleaseConcepts
                    ? [
                        _vm.createdRelease
                          ? _c("div", { staticClass: "mt-5" }, [
                              _c("p", [
                                _vm._v(_vm._s(_vm.createdRelease.Status))
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.createdRelease.ApiError))
                              ])
                            ])
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-4",
                      on: {
                        click: function($event) {
                          return _vm.$emit("create", _vm.testOption)
                        }
                      }
                    },
                    [_vm._v("Playlist aanmaken")]
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }